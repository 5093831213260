<template>
  <div class="message_mask_layer" :class="{'show': showMessage}" v-if="$store.state.isLogin">
    <div class="messagewrap" @click="go2message">
      <span class="ico"><i class="fa fa-envelope"></i></span>
      <p>미확인 쪽지 <font class="countcss" style="">{{ mcount }}</font>통 확인 해주세요</p>
    </div>
  </div>
</template>

<script>
  import {getUnCheckedMessageCount} from "@/network/userRequest";
  import {mapGetters} from 'vuex'

  export default {
    name: "MyUncheckedMessageComp",
    data() {
      return {
        showMessage: false,
      }
    },
    computed: {
      ...mapGetters({
        'mcount': 'getUnCheckedMessageCount',
      }),
    },
    methods: {
      go2message() {
        this.showMessage=false
        this.$router.replace('/front/message')
      }
    },
    created() {
      if(this.$store.state.isLogin){
        this.showMessage = this.$store.state.uncheckedMessageCount > 0;
        if (this.$route.name === 'message') {
          this.showMessage = false;
        }
      }else {
        this.showMessage = false;
      }

    },
    watch: {
      mcount(newVal) {
        if(this.$route.name !== 'message'){
          this.showMessage = this.$store.state.uncheckedMessageCount > 0
        } else {
          this.showMessage = false;
        }
      }
    }
  }
</script>

<style scoped>

  .message_mask_layer {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    display: none;
  }

  .show {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .messagewrap{
    width: 30rem;
    height: 4rem;
    background: rgba(105, 105, 105, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    color: floralwhite;
    border-radius: 5rem;
    cursor: pointer;
    position: relative;
  }
  .messagewrap .countcss{
    font-weight: bold;color:#ff973e;
  }
  .messagewrap .ico{
    position: absolute;top:.3rem;left: 2rem;font-size: 2rem
  }


  @media screen and (max-width: 800px) {

  }

  .popup_message .message_box {
    position: relative;
    cursor: pointer;
  }

  .popup_message .message_box .count01 {
    position: absolute;
    top: 48px;
    left: 44px !important;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    display: block;
    line-height: 26px;
  }

  .popup_message .message_box .count02 {
    position: absolute;
    top: 48px;
    left: 115px;
    font-size: 16px;
    width: 80%;
    font-weight: bold;
    text-align: center;
    display: inline-block;;
    color: #b30024;
    line-height: 26px;
  }
</style>